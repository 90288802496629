<template>
  <div class="edudash-date-picker">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="true"
      transition="scale-transition"
      offset-y
      class="date-picker"
      min-width="initial"
      :nudge-left="nudgeLeft"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="formattedDate"
          class="date-picker-input"
          :class="disabledClass"
          v-bind="attrs"
          readonly
          solo
          flat
          rounded
          dense
          outlined
          color="secondary"
          height="24"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="selectedDate"
        :events="events"
        :change="emitChange()"
        :max="maxDate"
        :min="minDate"
        event-color="success"
        no-title
        scrollable
        actions
        locale="pt-br"
      />
    </v-menu>
  </div>
</template>

<script>
import { format, parse } from 'date-fns';

export default {
  name: 'EdudashDatePicker',
  model: {
    prop: 'date',
    event: 'change',
  },
  props: {
    date: {
      type: String,
      default: new Date().toISOString().slice(0, 10),
    },
    events: {
      type: Array,
      default: () => [],
    },
    maxDate: {
      type: String,
      default: new Date().toISOString().slice(0, 10),
    },
    minDate: {
      type: String,
      default: null,
    },
    nudgeLeft: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      selectedDate: this.date,
    };
  },
  computed: {
    formattedDate() {
      const date = parse(this.selectedDate, 'yyyy-mm-dd', new Date());
      return format(date, 'dd/mm/yyyy');
    },
    disabledClass() {
      return this.disabled && 'disabled';
    },
  },
  watch: {
    date(value) {
      this.selectedDate = value;
    },
  },
  methods: {
    emitChange() {
      this.$emit('change', this.selectedDate);
    },
  },
};
</script>
<style lang="scss" scoped>
.edudash-date-picker {
  .date-picker-input.v-input ::v-deep {
    .v-input__control {
      height: 24px;
      min-height: 24px;
      .v-input__slot {
        height: 24px;
        padding: 0 8px 0 16px;
        min-height: 24px !important;
        height: 24px;
        fieldset {
          border: 2px solid $border-color-thick;
        }
        .v-text-field__slot {
          input {
            @include font-monteserrat-bold;
            font-size: $font-size-extra-super-small;
            color: $text-color-secondary;
          }
        }
      }
    }
  }

  .date-picker-input.disabled.v-input ::v-deep{
    .v-input__slot {
      background-color: $background-disabled-color;
    }
  }
}
</style>
